import request from '@/utils/request'


// 查询订单-取消原因列表
export function listReason(query) {
  return request({
    url: '/cancel/reason/list',
    method: 'get',
    params: query
  })
}

// 查询订单-取消原因分页
export function pageReason(query) {
  return request({
    url: '/cancel/reason/page',
    method: 'get',
    params: query
  })
}

// 查询订单-取消原因详细
export function getReason(data) {
  return request({
    url: '/cancel/reason/detail',
    method: 'get',
    params: data
  })
}

// 新增订单-取消原因
export function addReason(data) {
  return request({
    url: '/cancel/reason/add',
    method: 'post',
    data: data
  })
}

// 修改订单-取消原因
export function updateReason(data) {
  return request({
    url: '/cancel/reason/edit',
    method: 'post',
    data: data
  })
}

// 删除订单-取消原因
export function delReason(data) {
  return request({
    url: '/cancel/reason/delete',
    method: 'post',
    data: data
  })
}
